$primary: #c6f120;
$secondary: #ffffff;
$text-grey: #848795;
$bsc: #f0b90b;
$hunt: #fc6f6f;
$pancake: #d1884f;
$onesmarket: #ff4142;
$eth: #627eea;
$text-light-grey: #828589;
$bg: #141415;
$warning: #e02020;
$discord-zindex: 8;
$header-zindex: 9;
$component-zindex: 10;
$modal-zindex: 10;
$connected-wallet-zindex: 99999;
$mobile-menu-zindex: 99998;
$loading-zindex: 999999;
$modal-zindex: 9999999;
$wrong-network-zindex: 99999999;
