@import url("https://fonts.googleapis.com/css?family=Cookie");

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  width: 100%;
  height: 100%;
  background-color: #111229;
  font-family: "Cookie", cursive;
  overflow-x: hidden;
}

@for $i from 0 through 50 {
  .top-#{$i * 5} {
    margin-top: #{$i * 0.3}rem;
  }

  .right-#{$i * 5} {
    margin-right: #{$i * 0.3}rem;
  }

  .bottom-#{$i * 5} {
    margin-bottom: #{$i * 0.3}rem;
  }

  .left-#{$i * 5} {
    margin-left: #{$i * 0.3}rem;
  }

  .padding-#{$i * 5} {
    padding: #{$i * 0.3}rem;
  }

  .width-#{$i * 2} {
    width: #{$i * 0.125}rem;
  }

  .height-#{$i * 2} {
    height: #{$i * 0.125}rem;
  }

  .font-#{$i * 2} {
    font-size: #{$i * 0.125}rem;
  }
}

#snow-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 100vh;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}

.hi {
  font-size: 50px;
  color: #fff;
  text-align: center;
  letter-spacing: 3px;
}

.drop {
  position: absolute;
  top: 0;
  z-index: 1;
  opacity: 0;
}
.snow {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #fff;
}

.animate {
  animation: falling 8.5s infinite ease-in;
}

.info {
  width: 100vw;
  position: relative;
  z-index: 999;

  .blank {
    background-color: transparent;
    width: 100vw;
    height: 100vh;
  }

  .content {
    width: 100vw;
    min-height: 100vh;
    padding: 2.5rem;
    background-color: #f24236;
  }

  .us {
    max-width: 480px;
    width: 100%;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
  }
}

@keyframes falling {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 1500px;
    opacity: 0;
  }
}
