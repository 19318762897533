@keyframes rainbow {
  100%,
  0% {
    border-color: rgb(255, 0, 0);
  }
  8% {
    border-color: rgb(255, 127, 0);
  }
  16% {
    border-color: rgb(255, 255, 0);
  }
  25% {
    border-color: rgb(127, 255, 0);
  }
  33% {
    border-color: rgb(0, 255, 0);
  }
  41% {
    border-color: rgb(0, 255, 127);
  }
  50% {
    border-color: rgb(0, 255, 255);
  }
  58% {
    border-color: rgb(0, 127, 255);
  }
  66% {
    border-color: rgb(0, 0, 255);
  }
  75% {
    border-color: rgb(127, 0, 255);
  }
  83% {
    border-color: rgb(255, 0, 255);
  }
  91% {
    border-color: rgb(255, 0, 127);
  }
}
