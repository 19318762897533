@mixin ellipsis($width: 100%) {
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  vertical-align: middle;
}

@mixin absoluteCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absoluteCenterX() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absoluteCenterY() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin placeholderColor($color) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
}

@mixin overflowLine($lineHeight, $numLine) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $numLine; /* number of lines to show */
  line-height: $lineHeight; /* fallback */
  max-height: $lineHeight * $numLine; /* fallback */
}

@mixin responsive($media) {
  @if $media == tiny {
    @media only screen and (max-width: 330px) {
      @content;
    }
  } @else if $media == mobile {
    @media only screen and (max-width: 430px) {
      @content;
    }
  } @else if $media == tablet {
    // 1 column
    @media only screen and (max-width: 1000px) {
      @content;
    }
  } @else if $media == ipadpro {
    // 2 columns
    @media only screen and (max-width: 1380px) {
      @content;
    }
  } @else if $media == mbp13 {
    // 2 columns
    @media only screen and (max-width: 1439px) {
      @content;
    }
  } @else if $media == bigscreen {
    // 2 columns
    @media only screen and (min-width: 1440px) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $media) {
      @content;
    }
  }
}

@mixin mobileToggle($breakpoint) {
  .mobile-hidden {
    display: flex;

    @include responsive($breakpoint) {
      display: none;
    }
  }

  .mobile-visible {
    display: none;

    @include responsive($breakpoint) {
      display: flex;
    }
  }
}
