.invitation {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;

  .bonny {
    position: absolute;
    bottom: 2.5rem;
    @include absoluteCenterX();
  }
}
